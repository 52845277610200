import { createReducer, on } from '@ngrx/store';
import { initialExpertState } from '../state/expert.state';
import { expertTyping, setExpertName, setExpertImg } from '../actions/expert.actions';

export const expertReducer = createReducer(
  initialExpertState,
  on(setExpertName, (state, newState) => ({
    ...state,
    name: newState.name,
  })),
  on(setExpertImg, (state, newState) => ({
    ...state,
    img: newState.img,
  })),
  on(expertTyping, (state, newState) => ({
    ...state,
    isTyping: newState.isTyping,
  })),
);

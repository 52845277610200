import { initialProfileState } from '../state/profile.state';
import { createReducer, on } from '@ngrx/store';
import {
  setEventId,
  setMultiTaskSuccessStatus,
  setProfile,
  setUuid,
} from '../actions/profile.actions';
import { CookieService } from '../../shared/services/cookie.service';

export const profileReducer = createReducer(
  initialProfileState,
  on(setProfile, (_state, action) => ({
    ...action,
  })),
  on(setUuid, (state, { uuid }) => {
    CookieService.setCookie('uuid', uuid, {
      secure: true,
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString(),
      path: '/',
    });
    return {
      ...state,
      uuid,
    };
  }),
  on(setEventId, (state, { eventId }) => {
    return {
      ...state,
      eventId,
    };
  }),
  on(setMultiTaskSuccessStatus, (state, { isUserMultiTask }) => {
    return {
      ...state,
      isUserMultiTask,
    };
  }),
);

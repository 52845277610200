import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { appReducers } from './store/reducers/app.reducers';
import { ProfileEffects } from './store/effects/profile.effects';
import { Router, RouterModule } from '@angular/router';
import { BillingEffects } from './store/effects/biling.effects';
import { NgxMaskModule } from 'ngx-mask';
import { SubscriptionEffects } from './store/effects/subscription.effects';
import { HeaderComponentModule } from './components/layouts/header/header-component.module';
import { ModalComponentModule } from './components/modals/modal/modal-component.module';
import { NavigationComponentModule } from './components/layouts/navigation/navigation-component.module';

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    StoreModule.forRoot(appReducers),
    EffectsModule.forFeature([ProfileEffects, BillingEffects, SubscriptionEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([]),
    NgxMaskModule.forRoot(),
    HeaderComponentModule,
    ModalComponentModule,
    NavigationComponentModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

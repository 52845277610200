import { createAction, props } from '@ngrx/store';

export enum EventVariablesActions {
  SetFastSubActive = '[Test Variables] Set Fast Subscription Active Variable',
  SetIsCancellationFlowActive = '[Test Variables] Set Cancellation Flow',
  SetIsFreeUserRegistered = '[Test Variables] Set Is Free User Registered',
  SetIsUserMultitask = '[Test Variables] Set Is User Multitask',
  SetIsUserCompliance = '[Test Variables] Set Is User Compliance',
  // setProfilePage = '[Test Variables] Set Is Profile Page',
  setOrganicUser = '[User Variables] Set Organic User',
  setEuroPrice = '[Test Variables] Set Is Euro Price',
  setGbpPrice = '[Test Variables] Set Is Gbp Price',
  setMXPrice = '[Test Variables] Set Is MX Price',
  setCLPrice = '[Test Variables] Set Is CL Price',
  setBRPrice = '[Test Variables] Set Is BR Price',
  setPEPrice = '[Test Variables] Set Is PE Price',
}

export const setCanFastActivateSubscription = createAction(
  EventVariablesActions.SetFastSubActive,
  props<{ value: boolean }>(),
);

export const setCancellationFlowIsActive = createAction(
  EventVariablesActions.SetIsCancellationFlowActive,
  props<{ value: boolean }>(),
);

export const setIsFreeUserRegistered = createAction(
  EventVariablesActions.SetIsFreeUserRegistered,
  props<{ value: boolean }>(),
);

export const setIsUserMultitask = createAction(
  EventVariablesActions.SetIsUserMultitask,
  props<{ value: boolean }>(),
);

export const setIsUserCompliance = createAction(
  EventVariablesActions.SetIsUserCompliance,
  props<{ value: boolean }>(),
);

// export const setProfilePage = createAction(
//   EventVariablesActions.setProfilePage,
//   props<{ value: boolean }>(),
// );

export const setOrganicUser = createAction(
  EventVariablesActions.setOrganicUser,
  props<{ value: boolean }>(),
);

export const setEuroPrice = createAction(
  EventVariablesActions.setEuroPrice,
  props<{ value: boolean }>(),
);

export const setGbpPrice = createAction(
  EventVariablesActions.setGbpPrice,
  props<{ value: boolean }>(),
);

export const setMXPrice = createAction(
  EventVariablesActions.setMXPrice,
  props<{ value: boolean }>(),
);

export const setCLPrice = createAction(
  EventVariablesActions.setCLPrice,
  props<{ value: boolean }>(),
);

export const setBRPrice = createAction(
  EventVariablesActions.setBRPrice,
  props<{ value: boolean }>(),
);

export const setPEPrice = createAction(
  EventVariablesActions.setPEPrice,
  props<{ value: boolean }>(),
);

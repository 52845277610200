import { createAction, props } from '@ngrx/store';
import { SubscriptionStoreInterface } from '../models/subscription-store.interface';

export enum SubscriptionActions {
  GetSubscriptionInfo = '[Subscription] Get Subscription Info',
  SetSubscriptionInfo = '[Subscription] Set Subscription Info',
}

export const getSubscriptionInfo = createAction(SubscriptionActions.GetSubscriptionInfo);

export const setSubscriptionInfo = createAction(
  SubscriptionActions.SetSubscriptionInfo,
  props<SubscriptionStoreInterface>(),
);

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function getSentryDsnUrl(): string {
  const host = window.location.hostname;
  let dsnUrl = '';
  switch (host) {
    case 'app.expertchat.me':
      dsnUrl = 'https://c651c6a4b2ce44fdbe6268f0be0fdad7@sentry.fx2.io/10';
      break;
    case 'app.solving.homes':
      dsnUrl = 'https://48a49f0df62443abbf671a35f3de8181@sentry.fx2.io/17';
      break;
    case 'app.matheo.pro':
      dsnUrl = 'https://fdfc28418eb54976a546b2def56b505e@sentry.fx2.io/18';
      break;
    case 'app.mathsolver.io':
      dsnUrl = 'https://3f6b6b28789145eb9096277a4056153b@sentry.fx2.io/19';
      break;
  }
  return dsnUrl;
}

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: getSentryDsnUrl(),
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', environment.expertChatApi],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

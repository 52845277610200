import { createAction, props } from '@ngrx/store';
import { ProfileStoreInterface } from '../models/profile-store.interface';

export enum ProfileActions {
  GetProfile = '[Profile] Get Profile Data',
  SetProfile = '[Profile] Set Profile Data',
  SetUuid = '[Profile] Set User Uuid',
  SetEventId = '[Profile] Set Event Id',
  SetUserMultiTask = '[Profile] Set Multi Task Status',
  SetUserMultiTaskSuccess = '[Profile] Set Multi Task Success Status',
}

export const getProfile = createAction(ProfileActions.GetProfile);

export const setProfile = createAction(ProfileActions.SetProfile, props<ProfileStoreInterface>());

export const setUuid = createAction(ProfileActions.SetUuid, props<{ uuid: string }>());

export const setEventId = createAction(ProfileActions.SetEventId, props<{ eventId: string }>());

export const setMultiTaskStatus = createAction(ProfileActions.SetUserMultiTask);

export const setMultiTaskSuccessStatus = createAction(
  ProfileActions.SetUserMultiTaskSuccess,
  props<{ isUserMultiTask: boolean }>(),
);

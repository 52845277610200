import { createReducer, on } from '@ngrx/store';
import { initialEventVariablesState } from '../state/event-varibles.state';
import {
  setCancellationFlowIsActive,
  setCanFastActivateSubscription,
  setIsFreeUserRegistered,
  setIsUserMultitask,
  setIsUserCompliance,
  // setProfilePage,
  setEuroPrice,
  setOrganicUser,
  setGbpPrice,
  setMXPrice,
  setBRPrice,
  setCLPrice,
  setPEPrice,
} from '../actions/event-variables.actions';

export const eventVariablesReducer = createReducer(
  initialEventVariablesState,
  on(setCanFastActivateSubscription, (state, action) => ({
    ...state,
    canFastActivateSubscription: action.value,
  })),
  on(setCancellationFlowIsActive, (state, action) => ({
    ...state,
    cancellationFlowIsActive: action.value,
  })),
  on(setIsFreeUserRegistered, (state, action) => ({
    ...state,
    isFreeUserRegistered: action.value,
  })),
  on(setIsUserMultitask, (state, action) => ({
    ...state,
    isUserMultitask: action.value,
  })),
  on(setIsUserCompliance, (state, action) => ({
    ...state,
    isUserCompliance: action.value,
  })),
  // on(setProfilePage, (state, action) => ({
  //   ...state,
  //   isProfilePage: action.value,
  // })),
  on(setOrganicUser, (state, action) => ({
    ...state,
    isOrganicUser: action.value,
  })),
  on(setEuroPrice, (state, action) => ({
    ...state,
    isEuroPrice: action.value,
  })),
  on(setGbpPrice, (state, action) => ({
    ...state,
    isGbpPrice: action.value,
  })),
  on(setMXPrice, (state, action) => ({
    ...state,
    isMXPrice: action.value,
  })),
  on(setBRPrice, (state, action) => ({
    ...state,
    isBRPrice: action.value,
  })),
  on(setCLPrice, (state, action) => ({
    ...state,
    isCLPrice: action.value,
  })),
  on(setPEPrice, (state, action) => ({
    ...state,
    isPEPrice: action.value,
  })),
);

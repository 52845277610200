import { SubscriptionStoreInterface } from '../models/subscription-store.interface';

export const initialSubscriptionState: SubscriptionStoreInterface = {
  daysLeft: 0,
  endDate: 0,
  productName: '',
  productPrice: '',
  startDate: 0,
  cancellationFlow: {
    trialAttemptUsed: false,
    regularAttemptUsed: false,
  },
};

import { EventVariablesStoreInterface } from '../models/event-variables-store.interface';

export const initialEventVariablesState: EventVariablesStoreInterface = {
  canFastActivateSubscription: false,
  cancellationFlowIsActive: false,
  isFreeUserRegistered: false,
  isUserCompliance: false,
  // isProfilePage: false,
  isOrganicUser: false,
  isEuroPrice: false,
  isGbpPrice: false,
  isMXPrice: false,
  isCLPrice: false,
  isBRPrice: false,
  isPEPrice: false,
};

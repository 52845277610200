import { ActionReducerMap } from '@ngrx/store';
import { AppStateInterface } from '../state/app.state';
import { profileReducer } from './profile.reducers';
import { billingReducer } from './billing.reducers';
import { expertReducer } from './expert.reducers';
import { subscriptionReducer } from './subscription.reducers';
import { eventVariablesReducer } from './event-variables.reducers';
import { systemReducer } from './system.reducers';

export const appReducers: ActionReducerMap<AppStateInterface> = {
  profile: profileReducer,
  billing: billingReducer,
  expert: expertReducer,
  subscription: subscriptionReducer,
  eventVariables: eventVariablesReducer,
  systemState: systemReducer,
};
